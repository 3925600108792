import React from "react";
import "./footer.css";
import LogoLinks from "./LogoLinks";

function Footer() {
  return (
    <div className="container-fluid bg-dark text-light  footer ">
      <div className="row ">
        <div className="col-12 text-center ">
          <p className="mt-3 mb-3">Aron Mahuika 2023</p>
          <LogoLinks color={"light"} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
