import React from "react";

function ProjectCard({ image, title, subtitle, text, url, demoUrl }) {
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-11 col-lg-10">
        <div className="card mt-5">
          <div className="row text-center text-md-start  p-5 ">
            <div className="col-12 col-md-3">
              <div>
                <img
                  src={image}
                  alt={title + " project image"}
                  width={130}
                  height={130}
                  className="rounded-circle"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0">
              <h3 className="card-title">{title}</h3>
              <h6 className="card-subtitle mb-3 text-muted">{subtitle}</h6>
              <p className="card-text">{text}</p>
            </div>
            <div className="col-12 col-md-3 mt-4 mt-md-0 d-flex flex-column justify-content-center align-items-center">
              {demoUrl && (
                <a
                  href={demoUrl}
                  className=" btn px-4 btn-outline-dark mb-3 "
                  rel="noreferrer"
                  target="_blank"
                >
                  View demo <i className="bi bi-arrow-right-circle"></i>
                </a>
              )}
              <a
                href={url}
                className=" btn px-4 btn-outline-dark "
                rel="noreferrer"
                target="_blank"
              >
                View live application{" "}
                <i className="bi bi-arrow-right-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
