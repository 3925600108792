import "./App.css";
import Card from "./components/Card";
import Footer from "./components/Footer";
import ProjectCard from "./components/ProjectCard";
import AOS from "aos";
import "aos/dist/aos.css";
import { projects } from "./Data/ProjectsData";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="header-section">
        <div
          className="col-10 col-sm-8 col-md-6 col-lg-4"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
        >
          <Card />
        </div>
      </div>
      {/* projects section */}
      <div id="projects" className=" container-fluid pt-5">
        <div className="pt-5" data-aos="zoom-in-up" data-aos-duration="1500">
          <h1 className="text-center text-light mt-5">Projects</h1>
          <hr className="ms-auto text-light me-auto" style={{ width: "25%" }} />
        </div>

        {projects.map((project) => (
          <div data-aos="zoom-in-up" data-aos-duration="1500">
            <ProjectCard
              key={project.id}
              title={project.title}
              subtitle={project.subtitle}
              text={project.text}
              image={project.image}
              url={project.url}
              demoUrl={project.demoUrl}
            />
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
}

export default App;
