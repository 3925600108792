import nzMaoriPlaceNames from "../images/nzNameGame.jpg";
import indigoMassage from "../images/indigoMassage.png";
import bookShop from "../images/bookshop.png";
import HiitMe from "../images/hiitMe.png";
import EtuPapaImage from "../images/EtuPapaLogo.jpg";

export const projects = [
  {
    id: 1,
    image: nzMaoriPlaceNames,
    title: "New Zealand Maori Place Names",
    subtitle: "React.JS",
    text: "A game where users have to translate the names of places in New Zealand from Maori to English.",
    url: "https://maori-place-name-game.netlify.app/",
  },
  {
    id: 2,
    image: indigoMassage,
    title: "Indigo Massage",
    subtitle: "React.JS, Redux, ASP.NET Core Web API, SQL Server",
    text: "A website for my clients massage business that allows users to book appointments and view the services offered. A Admin page allows the owner to manage her availability, bookings and services.",
    demoUrl: "https://indigomassage.netlify.app/",
    url: "https://indigomassagetherapy.co.nz/",
  },
  {
    id: 3,
    image: bookShop,
    title: "Book Shop",
    subtitle: "ASP.NET Core MVC, SQL Server, Stripe payment API",
    text: "A website for a book shop that allows users to view and purchase books online. An Admin page allows the owner to manage books, users and orders.",
    url: "https://bookshop.aronmahuika.com/",
  },
  {
    id: 4,
    image: EtuPapaImage,
    title: "Etu Papa Wellbeing",
    subtitle: "React.JS, Redux, ASP.NET Core Web API, SQL Server",
    text: "E-Commerce website that offers massage services and sells clothing this is a demo.",
    url: "https://etupapademo.aronmahuika.com/",
  },
  {
    id: 5,
    image: HiitMe,
    title: "Hiit Me",
    subtitle: "JavaScript, HTML, CSS",

    text: "A website that generates a HIIT workout routine. Users can input the length of the workout. The website then generates a random workout routine.",
    url: "https://amahuika.github.io/HIIT-ME/",
  },
];
