import React from "react";

function LogoLinks({ color }) {
  return (
    <>
      <a
        href="https://github.com/amahuika"
        rel="noreferrer"
        target="_blank"
        className={`text-${color} me-4`}
      >
        <i className="fs-2 bi bi-github"></i>
      </a>
      <a
        href="https://www.linkedin.com/in/aron-mahuika-45b45b21a/"
        rel="noreferrer"
        target="_blank"
        className={`text-${color} me-4`}
      >
        <i className="fs-2 bi bi-linkedin"></i>
      </a>
      {/* instagram */}
      <a
        href="https://www.instagram.com/akorn04/"
        rel="noreferrer"
        target="_blank"
        className={`text-${color} me-4`}
      >
        <i className="fs-2 bi bi-instagram"></i>
      </a>

      {/* email */}
      <a href="mailto:amahuika@hotmail.com" className={`text-${color}`}>
        <i className="fs-2 bi bi-envelope"></i>
      </a>
    </>
  );
}

export default LogoLinks;
