import React from "react";
import profilePic from "../images/profilepic.png";
import LogoLinks from "./LogoLinks";

function Card() {
  return (
    <div className="card">
      <div className="card-body p-4 text-center">
        <img
          src={profilePic}
          alt="Head shot of person"
          className="rounded-circle"
          width={130}
        />
        <h2 className="card-title mt-3">Aron Mahuika</h2>
        {/* small under line */}
        <hr className="ms-auto me-auto" style={{ width: "25%" }} />
        <h5 className="card-subtitle mb-2 text-muted">
          Software Developer Graduate
        </h5>

        <p className="card-text">
          I am a Software developer graduate with a passion for learning new
          things and solving problems.
        </p>

        <LogoLinks color={"dark"} />
        <div className="mt-3">
          <a
            href="#projects"
            className="fs-5 btn btn-outline-secondary form-control d-flex justify-content-between"
          >
            Projects <i class="fs-5 bi bi-arrow-down-circle"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Card;
